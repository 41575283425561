@import "styles/resources";

.plant-modal {
  @include position(fixed, 0);
  @include flex(justify-center align-center);
  z-index: $z8;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;

  .overlay {
    @include position(absolute, 0);
    z-index: 1 !important;
    background: rgba(color("black"), 0.3);
    opacity: 0;
    transition: opacity 300ms;
  }
  .modal-wrapper {
    @include flex(column);
    @include size(100%, 90%);
    min-width: 25em;
    max-width: 45em;
    max-height: 100%;
    position: relative;
    z-index: 2;
    margin: auto;
    background: color("white");
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.75, 0.75);
    transition: transform 300ms;
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;

    .overlay {
      opacity: 1;
    }
    .modal-wrapper {
      transform: scale(1);
    }
  }

  .banner {
    @include flex(column align-center justify-center);
    position: relative;
    padding: 50px 0;
    border-bottom: 1px solid color("mineshaft");
    background-color: color("dustygray");
    background-position: center;
    background-size: cover;

    .heading,
    .text,
    h4 {
      position: relative;
      z-index: $z2;
      color: color("white");
      text-align: center;
    }
    .heading {
      margin-bottom: 0.25em;
      font-size: 38px;
      font-weight: bold;
    }
  }
  .overlay {
    @include position(absolute, 0 0 0 0);
    z-index: $z1;
    background: rgba(0, 0, 0, 0.5);
  }

  .content {
    @include flex(column);
    flex: 1 1 99%;

    .text {
      padding: 50px;
      padding-bottom: 0;
      color: color("emperor");
      font-size: $h5;

      ul {
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }
    iframe {
      width: 100%;
      height: 100%;
      min-height: 700px;
      position: relative;
      z-index: $z1;
      margin-top: 10px;
      padding: 50px;
      padding-top: 0;
    }
  }

  .close-button {
    @include position(absolute, 1em 1em null null);
    @include size(1.75em);
    z-index: $z2;
    cursor: pointer;

    svg {
      fill: color("white");
    }
  }
}
