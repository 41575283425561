@import "styles/resources";

.modal-container {
  @include flex("justify-center" "align-center");
  @include position(fixed, 0);
  z-index: $z8;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: opacity 300ms;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: auto;

    .overlay {
      opacity: 0.3;
      pointer-events: auto;
    }

    .modal {
      transform: scale(1, 1);
    }
  }

  .overlay {
    @include position(fixed, 0);
    background-color: color("black");
    opacity: 0;
    transition: opacity 300ms;
    pointer-events: none;
  }

  .modal {
    @include size(100%, 60%);
    min-width: 25em;
    max-width: 45em;
    max-height: 100%;
    position: relative;
    background: color("white");
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.75, 0.75);
    transition: transform 300ms;

    .inner-content {
      padding: 2.75em 4.125em 1.5em 3.125em;
    }

    h1 {
      margin-bottom: 0.5em;
      color: color("mineshaft");
    }

    &-body {
      @include article();
      margin: 1.2em 0;
      color: color("emperor");

      p,
      li {
        font-size: $base;
      }

      a {
        color: color("alizarincrimson");
      }
    }
  }

  .modal-close-button {
    @include position(absolute, 1em 2em null null);
    @include size(1.75em);
    z-index: $z1;
    cursor: pointer;

    svg {
      fill: color("alizarincrimson");
    }
  }
}
