@import "styles/resources";

.simple-card-list {
  @include flex(wrap justify-center);
  @include size(100%, auto);
  position: relative;
  padding: 3em;

  .simple-card {
    margin: 0 1.5em 3em;
  }

  .closer {
    @include position(absolute, 1em 1em null null);
    cursor: pointer;

    svg {
      @include size(1.5em);
      fill: color("alizarincrimson");
    }
  }
}
