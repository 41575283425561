@import "styles/resources";

#plant-view {
  position: relative;
  z-index: $z1;

  .active-info {
    @include position(absolute, 0 0 0 0);
    z-index: $z1;
    background: color("white");
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 300ms, opacity 300ms;
    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
