@import "styles/resources";

.drawer-item {
  @include flex(align-center justify-between);
  padding: 0.875em 1.75em;
  background-color: transparent;
  color: color("white");
  font-size: $h3;
  transition: background-color 300ms, color 300ms;
  cursor: pointer;

  svg {
    @include size(1em);
    fill: color("emperor");
    transform: translateX(0);
    transition: fill 300ms, transform 300ms;
  }

  &.is-active {
    background-color: color("wildsand");
    color: color("mineshaft");

    svg {
      fill: color("alizarincrimson");
    }
  }

  &:hover:not(.is-active) {
    svg {
      fill: color("wildsand");
      transform: translateX(0.25em);
    }
  }
}
