@import "styles/resources";

.simple-card {
  @include flex(column);
  width: 15em;
  z-index: 0;
  border-radius: 8px;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16);
  transform: translate3d(0, 0, 0);

  .thumbnail {
    @include flex(align-center justify-center);
    @include size(240px, 185px);
    position: relative;
    cursor: pointer;

    a {
      @include position(absolute, 0);
      z-index: 3;
      opacity: 0;
      user-select: none;
    }

    img {
      @include border-top-radius(8px);
    }

    .radial-overlay {
      @include position(absolute, 0);
      @include border-top-radius(8px);
      z-index: 1;
      background-image: radial-gradient(
        ellipse,
        transparent 0%,
        rgba(0, 0, 0, 0.5) 100%
      );
      transform: translate3d(0, 0, 0);
      pointer-events: none;
    }

    .overlay {
      @include flex(align-center justify-center);
      @include position(absolute, 0);
      z-index: 2;
      transform: translate3d(0, 0, 0);
      pointer-events: none;

      .background {
        @include position(absolute, 0);
        @include border-top-radius(8px);
        background-color: color("black");
        opacity: 0;
        transition: all 300ms;
      }

      .copy {
        position: relative;
        opacity: 0;
        color: color("white");
        font-size: $h5;
        transform: translateY(70%);
        transition: all 200ms;
      }

      &.is-active {
        .background {
          opacity: 0.75;
        }

        .copy {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  .content {
    @include flex(column);
    @include border-width(0 1px 1px 1px);
    @include border-bottom-radius(8px);
    flex-grow: 1;
    padding: 0.875em 0.625em 0.625em;
    border-style: solid;
    border-color: color("dustygray");
    background-color: color("white");
    color: color("mineshaft");
  }

  .info {
    flex-grow: 1;
  }

  .buttons {
    @include flex(align-center justify-between);
    margin-top: 1.5em;
    padding-left: 0.5em;

    svg {
      @include size(2.625em);
      fill: color("emperor");
      transition: fill 300ms;

      &:hover {
        fill: color("cerulean");
      }
    }
  }
}
