@import "styles/resources";

.bb-item {
  @include flex(justify-center align-center);
  flex: 0 0 $butt-bar-item-width;
  // 2px here accounts for the top border on the bar
  height: calc(#{$butt-bar-height} - 2px);
  padding: 0 0.5em;
  border-right: 1px solid color("dustygray");
  background: color("white");
  color: color("emperor");
  text-align: center;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;

  &:first-of-type {
    margin-left: auto;
    border-left: 1px solid color("dustygray");
  }

  &:last-of-type {
    margin-right: auto;
  }

  &.is-active {
    background: color("alizarincrimson");
    color: color("white");
  }

  &.is-hover {
    background: color("alizarincrimson");
    color: color("white");
  }

  &.is-disabled {
    color: color("dustygray");
    pointer-events: none;
  }
}
