@import "styles/resources";

#filter-selector-container {
  width: 100%;
  padding-top: 3em;

  .selector {
    @include flex(align-center);
    @include size(100%, 50px);
    padding: 0 1em;
    border: 1px solid color("dustygray");
    color: color("mineshaft");
    cursor: pointer;
    user-select: none;

    &.is-open {
      border-color: color("mineshaft");

      svg {
        fill: color("cerulean");
        transform: rotate(180deg);
      }
    }

    small {
      flex: 1 0 auto;
    }

    svg {
      @include size(1em);
      fill: color("mineshaft");
    }
  }

  .options {
    @include border-width(0 1px 1px);
    display: none;
    border-style: solid;
    border-color: color("dustygray");

    &.is-open {
      display: block;
    }

    .clear-filters {
      @include flex(align-center);
      @include size(100%, 40px);
      padding: 0 1em;
      border-bottom: 1px solid color("dustygray");
      background-color: color("wildsand");
      color: color("cerulean");
      cursor: pointer;

      svg {
        @include size(0.75em);
        margin-right: 0.25em;
        fill: color("cerulean");
      }
    }

    .options-wrapper {
      @include flex(column align-start);
    }

    .filter {
      @include flex(align-center);
      flex: 1 0 40px;
      width: 100%;
      padding: 0 1em;
      background-color: transparent;
      color: color("mineshaft");
      transition: background-color 300ms, color 300ms;
      cursor: pointer;

      &:hover,
      &.is-active {
        background-color: color("cerulean");
        color: color("white");
      }
    }
  }
}
