@import "styles/resources";

.image-placeholder {
  @include size(100%);
  position: relative;
  z-index: 0;
  text-align: center;

  &.is-loaded {
    .underlay {
      opacity: 0;
    }
    img {
      opacity: 1;
    }
  }

  .underlay {
    @include flex(align-center justify-center);
    @include position(absolute, 0);
    z-index: -1;
    background-color: color("wildsand");
    opacity: 1;
    transition: opacity 300ms;

    svg {
      @include size(6em);
      fill: color("white");
    }
  }

  img {
    opacity: 0;
    transition: opacity 300ms;
  }
}
