@import "styles/resources";

#drawer {
  @include flex(column);
  @include position(fixed, 0 null 0 0);
  width: $drawer-width;
  z-index: 2;
  background-color: color("mineshaft");
  transform: translateX(-100%);
  transition: transform 300ms;

  &.is-active {
    transform: translateX(0);
  }

  #drawer-header {
    flex: 0 0 80px;
  }

  .drawer-items {
    @include flex(column justify-center);
    flex: 1 0 auto;
  }

  #drawer-footer {
    flex: 0 0 auto;
  }
}
