@import "styles/resources";

.tab-list {
  @include flex(justify-start align-center);
  position: relative;
  z-index: 0;
  margin-top: 1em;

  &::after {
    @include position(absolute, null null 0 0);
    content: "";
    width: 100%;
    z-index: 1;
    border-bottom: 2px solid color("wildsand");
  }

  .tab {
    z-index: 2;
    margin-right: 3em;
  }
}
