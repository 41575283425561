@import "styles/resources";

.product-image {
  @include flex(column align-center);
  height: 100%;
  position: relative;
  z-index: 0;
  padding: 4.5em;

  img {
    width: auto;
    max-height: 90%;
  }
}
