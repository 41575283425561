@import "styles/resources";

.tab-slide {
  @include flex(column);
  @include position(absolute, 0 0 0 0);
  z-index: $z3;
  padding-left: calc(#{$widget-size} + 4em);
  background: color("white");

  .close-icon {
    @include position(absolute, 1em 1em null null);
    cursor: pointer;

    svg {
      @include size(2em);
      fill: color("alizarincrimson");
    }
  }

  .tab-top {
    z-index: 3;
    padding-top: $widget-size;
    background-color: color("white");
  }

  .tab-content-container {
    flex: 1 0 auto;
    position: relative;
  }

  .tab-content {
    @include position(absolute, 0);
    z-index: 1;
    background-color: color("white");
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 300ms 300ms, transform 300ms 300ms;
    pointer-events: none;

    &.is-active {
      z-index: 2;
      opacity: 1;
      transform: translateY(0);
      transition: opacity 300ms, transform 300ms;
      pointer-events: auto;
    }
  }
}
