@import "styles/resources";

.menu-container {
  @include position(fixed, 0 null 0 0);
  width: $menu-width;
  background-color: color("wildsand");
  transform: translateX(-100%);
  transition: transform 300ms;

  &.tier-1 {
    z-index: 1;
    border-right: 1px solid color("dustygray");

    &.is-active {
      transform: translateX(#{$drawer-width});
    }
  }

  &.tier-2 {
    &.is-active {
      transform: translateX(#{$drawer-width + $menu-width});
    }
  }

  .menu-items {
    @include flex(column);
    height: 100%;
    padding-top: 4em;
  }
}
