@import "styles/resources";

.hotspot {
  position: absolute;
  z-index: 0;
  transition: opacity 300ms;

  .icon {
    @include size(2.5em, 3.813em);
    transition: opacity 300ms;
    cursor: pointer;
  }

  svg {
    .border,
    .arrow,
    .circle {
      transition: fill 300ms;
    }

    .border {
      fill: transparent;
    }

    .arrow {
      fill: color("white");
    }

    .circle {
      fill: color("alizarincrimson");
    }
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-active {
    z-index: 2;

    .icon {
      z-index: 1;
      opacity: 1;

      svg {
        .border {
          fill: color("white");
        }

        .arrow {
          fill: color("alizarincrimson");
        }

        .circle {
          fill: color("white");
        }
      }
    }

    .unit-snapshot {
      opacity: 1;
      transform: scale(1, 1);
      pointer-events: auto;
    }
  }

  .unit-snapshot {
    @include flex(align-center);
    @include size(31.25em, 11.175em);
    position: absolute;
    z-index: -1;
    border: 2px solid color("white");
    background-color: color("white");
    opacity: 0;
    transform: scale(0.75, 0.75);
    transition: all 300ms;
    cursor: pointer;
    pointer-events: none;

    &.top {
      top: 2.5em;
    }
    &.bottom {
      top: -10em;
    }

    &.left {
      left: -2.25em;
      transform-origin: 10% bottom;
    }

    &.right {
      right: -2.25em;
      transform-origin: 90% bottom;
    }

    .thumbnail {
      @include size(10.938em);
      flex: 0 0 10.938em;
    }

    .copy {
      padding: 0 0.625em 0 1.875em;
      h4 {
        font-size: 1.375rem;
      }
    }

    .cta {
      @include flex(align-center);
      margin-top: 1em;
    }

    p {
      color: color("alizarincrimson");
      font-size: $base;
      text-transform: uppercase;
    }

    svg {
      @include size(0.938em);
      margin-left: 0.25em;
      stroke: color("alizarincrimson");
      fill: color("alizarincrimson");
    }
  }
}
