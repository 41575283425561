@import "styles/resources";

.lit-sidebar {
  @include flex(column);
  @include size(100%);
  background: color("wildsand");

  .description {
    margin: 1.5em 0;

    > * {
      font-size: $h4;
      line-height: 1.75rem;
    }
  }

  .sidebar {
    &-top {
      flex-grow: 1;

      .top-group {
        margin: 4.5em 4.25em 2em 3em;
      }
    }
    &-bottom {
      @include flex();
      flex: 0 0 9em;

      .pager-item {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
