@import "styles/resources";

#bottom-bar {
  @include position(absolute, null 0 0 0);
  @include size(100%, $butt-bar-height);
  z-index: $z4;
  border-top: 2px solid color("alizarincrimson");
  background: color("white");
  transform: translateY($butt-bar-height);
  transition: transform 0.3s;

  &.is-active {
    transform: translateY(0);
  }

  .bb-group {
    @include flex(justify-start align-center);
  }
}
