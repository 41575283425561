@import "styles/resources";

.tier-one-item {
  .menu-item {
    transition: background-color 300ms;

    &.is-active {
      background-color: color("emperor");

      .copy {
        color: color("wildsand");
      }

      svg {
        fill: color("wildsand");
      }
    }
  }
}
