@import "styles/resources";

#image-view {
  .image-wrapper {
    @include size(100%);
    z-index: 0;
    padding: 6em 4.5em;
    border-radius: 5px;
    text-align: center;
  }

  img {
    max-height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 15px color("dustygray");
  }
}
